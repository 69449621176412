import { STANDARD_META_EVENTS } from '@/consts/socialTracking';

type TrackingPlatform = 'meta' | 'tiktok';

export function trackEvent(
    eventName: string,
    platforms: TrackingPlatform[] = ['meta', 'tiktok'],
    params: { [key: string]: any } = {},
): void {
    if (platforms.includes('meta') && typeof window.fbq === 'function') {
        const eventType = STANDARD_META_EVENTS.includes(eventName) ? 'track' : 'trackCustom';
        window.fbq(eventType, eventName, params);
    }

    if (platforms.includes('tiktok') && window.ttq && typeof window.ttq.track === 'function') {
        window.ttq.track(eventName, params);
    }
}

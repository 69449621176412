<template>
    <div v-if="isOpenTimed" class="fixed inset-0 bg-black/60 backdrop-blur-sm z-50" @click.prevent="$emit('close')">
        <transition
            enter-active-class="transition ease-out duration-500"
            enter-class="-translate-y-full"
            enter-to-class="translate-y-0"
            leave-active-class="transition ease-out duration-500"
            leave-class="translate-y-0"
            leave-to-class="-translate-y-full"
        >
            <aside
                v-if="showModal"
                class="relative rounded shadow-modal bg-gray-900 mt-4 sm:mt-8 mb-4 mx-4 sm:mx-auto sm:w-full sm:max-w-lg"
                @click.stop
            >
                <ais-instant-search
                    :search-client="searchClient"
                    :index-name="'release_groups'"
                    class="flex flex-col max-h-screen"
                >
                    <ais-configure
                        :hits-per-page.camel="4"
                        :filters.camel="`(releases.territories.include: ${shippingCountry} OR releases.territories.include: World) AND NOT releases.territories.exclude:  ${shippingCountry}`"
                    />
                    <ais-search-box reset-title="Clear" autofocus>
                        <template #default="{ currentRefinement, refine }">
                            <header
                                class="flex items-center border-t border-gray-700 bg-gray-800 relative w-full rounded rounded-b-none"
                            >
                                <font-awesome-icon
                                    class="ml-4 mt-1 text-2xl text-white"
                                    icon="fa-light fa-magnifying-glass"
                                />

                                <label for="search" hidden />

                                <input
                                    id="search"
                                    ref="searchInputRef"
                                    type="text"
                                    :value="currentRefinement"
                                    :placeholder="t('Search...')"
                                    class="block flex-1 leading-none bg-transparent py-4 px-4 text-lg text-white outline-none focus:outline-none ring-0 focus:ring-0 border-none focus:border-none"
                                    autocomplete="off"
                                    spellcheck="false"
                                    @input="refine(($event.currentTarget as HTMLInputElement).value)"
                                />

                                <font-awesome-icon
                                    class="text-xl text-gray-400 hover:text-white right-3 cursor-pointer mr-8"
                                    icon="fa-solid fa-xmark"
                                    @click="refine('')"
                                />

                                <a
                                    href="#"
                                    class="link-gray hover:no-underline dec mr-4"
                                    @click.prevent="$emit('close')"
                                    v-text="t('Close')"
                                />
                            </header>
                        </template>
                    </ais-search-box>

                    <ais-state-results>
                        <template #default="{ state: { query }, results: { hits } }">
                            <SearchResults
                                v-if="query.trim() !== ''"
                                class="mt-1 mb-4 sm:mb-8"
                                :hits="hits"
                                :search-client="searchClient"
                            />

                            <div v-else></div>
                        </template>
                    </ais-state-results>
                </ais-instant-search>
            </aside>
        </transition>
    </div>
</template>

<script setup lang="ts">
    import { computed, ComputedRef, nextTick, ref, watch } from 'vue';
    import { AisConfigure, AisInstantSearch, AisSearchBox, AisStateResults } from 'vue-instantsearch/vue3/es';
    import { useStore } from 'vuex';

    import algoliasearch from 'algoliasearch/lite';
    import { trans as t } from 'laravel-vue-i18n';

    import SearchResults from '@/components/global/search/SearchResults.vue';

    const store = useStore();

    const { showModalDefault } = defineProps({
        showModalDefault: {
            type: Boolean,
            default: false,
            required: false,
        },
    });

    const searchModalShown: ComputedRef<boolean> = computed(() => store.getters['search/searchModalShown']);
    const shippingCountry: ComputedRef<string> = computed(() => store.getters['locales/shippingCountry']);

    const searchClient = algoliasearch(
        import.meta.env.VITE_ALGOLIA_APP_ID as string,
        import.meta.env.VITE_ALGOLIA_SEARCH_KEY as string,
    );

    const showModal = ref<boolean>(showModalDefault);
    const isOpenTimed = ref<boolean>(showModalDefault);
    const searchInputRef = ref<HTMLInputElement | null>(null);

    watch(searchModalShown, async (open) => {
        if (open) {
            isOpenTimed.value = true;

            await nextTick();

            showModal.value = true;

            await nextTick();

            setTimeout(() => {
                if (searchInputRef.value) {
                    searchInputRef.value.focus();
                }
            }, 600);
        } else {
            showModal.value = false;

            setTimeout(() => {
                isOpenTimed.value = false;
            }, 600);
        }
    });

    defineExpose({
        AisInstantSearch,
        AisSearchBox,
        AisConfigure,
        AisStateResults,
        SearchResults,
    });
</script>

<template>
    <div v-if="isOpenTimed" class="fixed inset-0 bg-black/60 backdrop-blur-sm z-50" @click="close">
        <div class="flex items-end justify-center min-h-screen">
            <transition
                enter-active-class="transition ease-out duration-500"
                enter-class="translate-y-full"
                enter-to-class="translate-y-0"
                leave-active-class="transition ease-out duration-500"
                leave-class="translate-y-0"
                leave-to-class="translate-y-full"
            >
                <div
                    v-if="showMenu"
                    class="rounded rounded-b-none w-full shadow-modal text-xl leading-none grid bg-gray-800 gap-2 py-6"
                >
                    <a
                        v-if="showStoreLink"
                        href="/store"
                        class="rounded px-6 pt-3 pb-2 bg-white bg-opacity-0 hover:bg-opacity-10 transition ease-in-out duration-200 hover:text-white hover:no-underline group"
                    >
                        <p class="group-hover:underline">{{ t('Store') }}</p>

                        <p class="text-lg text-gray-200">
                            {{ t('Browse our catalogue') }}
                        </p>
                    </a>

                    <a
                        v-if="user.is_seller"
                        href="/es/pages"
                        class="rounded px-6 pt-3 pb-2 bg-white bg-opacity-0 hover:bg-opacity-10 transition ease-in-out duration-200 hover:text-white hover:no-underline group"
                    >
                        <p class="group-hover:underline">{{ t('Pages') }}</p>

                        <p class="text-lg text-gray-200">
                            {{ t('Manage your artist pages') }}
                        </p>
                    </a>

                    <a
                        v-if="user.is_seller"
                        href="/es/releases"
                        class="rounded px-6 pt-3 pb-2 bg-white bg-opacity-0 hover:bg-opacity-10 transition ease-in-out duration-200 hover:text-white hover:no-underline group"
                    >
                        <p class="group-hover:underline">
                            {{ t('Releases') }}
                        </p>

                        <p class="text-lg text-gray-200">
                            {{ t('Manage your releases') }}
                        </p>
                    </a>

                    <a
                        v-if="user.is_seller"
                        href="/es/sales"
                        class="rounded px-6 pt-3 pb-2 bg-white bg-opacity-0 hover:bg-opacity-10 transition ease-in-out duration-200 hover:text-white hover:no-underline group"
                    >
                        <p class="group-hover:underline">
                            {{ t('Sales') }}
                        </p>

                        <p class="text-lg text-gray-200">
                            {{ t('Manage your earnings') }}
                        </p>
                    </a>

                    <!-- <a
                        href="/es/orders"
                        class="rounded px-6 pt-3 pb-2 bg-white bg-opacity-0 hover:bg-opacity-10 transition ease-in-out duration-200 hover:text-white hover:no-underline group"
                    >
                        <p class="group-hover:underline">{{ t('Orders') }}</p>

                        <p class="text-lg text-gray-200">
                            {{ t('View of your orders') }}
                        </p>
                    </a> -->

                    <a
                        href="/es/account"
                        class="rounded px-6 pt-3 pb-2 bg-white bg-opacity-0 hover:bg-opacity-10 transition ease-in-out duration-200 hover:text-white hover:no-underline group"
                    >
                        <p class="group-hover:underline">{{ t('Account') }}</p>

                        <p class="text-lg text-gray-200">
                            {{ t('Manage your account') }}
                        </p>
                    </a>

                    <a
                        v-if="user.is_admin"
                        href="/es/admin"
                        class="rounded px-6 pt-3 pb-2 bg-white bg-opacity-0 hover:bg-opacity-10 transition ease-in-out duration-200 hover:text-white hover:no-underline group"
                    >
                        <p class="group-hover:underline">{{ t('Admin') }}</p>
                    </a>

                    <a
                        href="#"
                        class="rounded px-6 pt-3 pb-2 bg-white bg-opacity-0 hover:bg-opacity-10 transition ease-in-out duration-200 hover:text-white hover:no-underline group"
                        @click.prevent="logout"
                    >
                        <p class="group-hover:underline">{{ t('Logout') }}</p>
                    </a>
                </div>
            </transition>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { defineExpose, nextTick, ref, watch } from 'vue';
    import { useStore } from 'vuex';

    import useAxiosErrorHandler from '@/composables/useAxiosErrorHandler';
    import { trans as t } from 'laravel-vue-i18n';

    import { User } from '@/types/user';

    const store = useStore();

    const { user, isOpenByDefault } = defineProps({
        user: {
            type: Object as () => User,
            required: true,
        },
        isOpenByDefault: {
            type: Boolean,
            required: false,
            default: false,
        },
        showStoreLink: {
            type: Boolean,
            default: false,
        },
    });

    const showMenu = ref(isOpenByDefault);
    const isOpenTimed = ref(isOpenByDefault);
    const isOpen = ref(isOpenByDefault);

    watch(isOpen, async (open) => {
        if (open) {
            isOpenTimed.value = true;

            await nextTick();

            showMenu.value = true;

            await nextTick();
        } else {
            showMenu.value = false;

            setTimeout(() => {
                // wait for transition
                isOpenTimed.value = false;
            }, 600);
        }
    });

    const logout = async () => {
        try {
            await store.dispatch('user/logout');
        } catch (error: any) {
            useAxiosErrorHandler(error, { notify: false });
        }
    };

    const open = () => {
        isOpen.value = true;
    };

    const close = () => {
        isOpen.value = false;
    };

    defineExpose({ open });
</script>
